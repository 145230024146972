import React from "react"
import Footer from "../components/footer"
import "./layout.css"
import Reveal from "react-reveal/Reveal"
import Header from "../components/header"
import Background from "../images/guides-background.jpg"
import { useAuth } from "gatsby-theme-firebase"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const BlogLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "guides-background.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { isLoggedIn } = useAuth()
  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      {/* <div
        style={{
          backgroundImage: `linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.4)
          ), url(${Background})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "white",
          padding: "0 2rem 3rem 2rem",
          fontWeight: "500",
          backgroundPosition: "center",
        }}
      > */}
      <BackgroundImage
        fluid={[
          `linear-gradient(
                  to bottom,
                  rgba(0, 0, 0, 0),
                  rgba(0, 0, 0, 0.4)
                )`,
          data.backgroundImage.childImageSharp.fluid,
        ]}
        alt="banner"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "white",
          padding: "0 2rem 3rem 2rem",
          fontWeight: "500",
          backgroundPosition: "center",
        }}
      >
        <Header siteTitle="You Change Earth" textColor="white" padding="0 0" />
        <h1 className="text-3xl mt-6 text-center">Individual Climate Guides</h1>
        <p className="mt-4 text-center">
          These are the projects that matter for our climate. Our research team
          has worked with experts for over a year to carefully choose the
          actions that allow you to best use your time and life choices to make
          a difference for the climate.
          {/* Or suggest tools/guides/steps you would add to the guides on this form. */}
        </p>
        <br />
        <p className="text-center">
          <Link
            to="/guiding-criteria"
            style={{ textDecoration: "none", color: "var(--green)" }}
          >
            Click here to see why these actions matter
          </Link>
        </p>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoggedIn ? (
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  marginTop: "1rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  Return to Dashboard
                </p>
              </div>
            </Link>
          ) : (
            <Link to="/mainform" style={{ textDecoration: "none" }}>
              <div
                className="button homepageButton"
                style={{
                  width: "12rem",
                  marginTop: "1rem",
                  color: "white",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                <p
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 700,
                    textDecoration: "none",
                  }}
                >
                  GET STARTED
                </p>
              </div>
            </Link>
          )}
        </div>
      </BackgroundImage>

      {/* </div> */}
      <Reveal>
        <main
          style={{
            margin: `0 auto`,
            maxWidth: 1366,
            padding: `0 1.0875rem 1.45rem`,
            minHeight: "100%",
            minWidth: "70%",
            flex: 1,
          }}
        >
          {children}
        </main>
      </Reveal>
      <Footer />
    </div>
  )
}

export default BlogLayout
