import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../../components/seo"
import BlogLayout from "../../layouts/blogLayout"
import BlogItem from "../../components/blogItem"
import Fade from "react-reveal/Fade"

const Guides = ({ data }) => {
  const headerStyle = {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: "0.5rem",
  }
  return (
    <BlogLayout>
      <SEO title="Guides" />
      <section>
        <div>
          {/* {data.allContentfulQuickActions.nodes.map(project => {
            return (
              <Fade>
                <BlogItem
                  id={project.id}
                  title={project.title}
                  time="A few minutes!"
                  category="For everyone!"
                  atAGlance={project.atAGlance}
                  slug={project.slug}
                  icon={project.icon}
                />
              </Fade>
            )
          })} */}
          {/* Career section */}
          <div style={{ marginTop: "1.5rem" }}>
            <h2 style={headerStyle}>Career-Based</h2>
            <p>
              We have 80,000 hours in our careers. You can orient your everyday
              work in your company or change roles to work in the fast growing
              sustainability field.
            </p>
            {data.allContentfulProjectNew.nodes.map(project => {
              if (project.projectType === "career") {
                return (
                  <Fade>
                    <div style={{ marginTop: "1.5rem" }}>
                      <BlogItem
                        id={project.id}
                        name={project.name}
                        description={project.description}
                        slug={project.slug}
                        icon={project.icon}
                      />
                    </div>
                  </Fade>
                )
              }
            })}
          </div>
          {/* Time section */}
          <div style={{ marginTop: "1.5rem" }}>
            <h2 style={headerStyle}>Use Your Time</h2>
            <p>
              We can each do so much for the planet if we take the time to. See
              if any of these suit your schedule and interests.
            </p>
            {data.allContentfulProjectNew.nodes.map(project => {
              if (project.projectType === "time") {
                return (
                  <Fade>
                    <div style={{ marginTop: "1.5rem" }}>
                      <BlogItem
                        id={project.id}
                        name={project.name}
                        description={project.description}
                        slug={project.slug}
                        icon={project.icon}
                      />
                    </div>
                  </Fade>
                )
              }
            })}
          </div>
          {/* Lifestyle section */}
          <div style={{ marginTop: "1.5rem" }}>
            <h2 style={headerStyle}>Lifestyle Changes</h2>
            <p>
              These are the few climate actions that will help society reach the
              2050 goals. Each of these guides reduces more than a ton of CO2
              each year (equivalent to planting around 16 trees). Take time to
              share and implement these if they suit you!
            </p>
            {data.allContentfulProjectNew.nodes.map(project => {
              if (project.projectType === "lifestyle") {
                return (
                  <Fade>
                    <div style={{ marginTop: "1.5rem" }}>
                      <BlogItem
                        id={project.id}
                        name={project.name}
                        description={project.description}
                        slug={project.slug}
                        icon={project.icon}
                      />
                    </div>
                  </Fade>
                )
              }
            })}
          </div>
          <div style={{ textAlign: "center", marginTop: "2rem" }}>
            <p>
              If you have a suggestion on how we can improve our current guides
              or feel that we have missed an important action,{" "}
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSd9urapBTL-r_0QtHCxXXTuqYF7hYf4tn4qUDYtZq5LArg2PA/viewform?usp=sf_link"
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "var(--green)",
                }}
              >
                please let us know on this form!{" "}
              </a>
              <br />
              <br />
              <Link
                to="/guiding-criteria"
                style={{ textDecoration: "none", color: "var(--green)" }}
              >
                Here is the guiding criteria for what our researchers publish
              </Link>
            </p>
          </div>
        </div>
      </section>
    </BlogLayout>
  )
}

export default Guides

export const query = graphql`
  {
    allContentfulProjectNew(sort: { fields: orderTag }) {
      nodes {
        id
        name
        description {
          json
        }
        icon {
          file {
            url
          }
        }
        slug
        projectType
      }
    }
  }
`
