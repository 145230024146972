import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const website_url = "https://youchangeearth.org"
const options = {
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  renderNode: {
    [INLINES.HYPERLINK]: node => {
      return (
        <a
          href={node.data.uri}
          target={`${
            node.data.uri.startsWith(website_url) ? "_self" : "_blank"
          }`}
          rel={`${
            node.data.uri.startsWith(website_url) ? "" : "noopener noreferrer"
          }`}
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

const BlogItem = props => {
  return (
    <Link
      style={{ textDecoration: "none", color: "inherit" }}
      to={"/guides/" + props.slug}
      id={props.id}
    >
      <div className="post-item" style={{ display: "flex" }}>
        <img
          style={{
            maxWidth: "60px",
            float: "left",
            marginRight: "20px",
          }}
          src={props.icon.file.url}
          alt="Icon for card here"
        />
        <div>
          <h1 style={{ fontSize: "1.3rem", marginBottom: "0.5rem" }}>
            {props.name}
          </h1>
          <p>{documentToReactComponents(props.description.json, options)}</p>
        </div>
      </div>
    </Link>
  )
}

BlogItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  slug: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  time: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  category: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  atAGlance: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default BlogItem
